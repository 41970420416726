<template>
  <div style="background: #24252A" id="headTitle">
<div class="line" id="navtit">
  <el-menu :default-active="activeIndex"
           class="el-menu-demo"
           mode="horizontal"
           @select="handleSelect"
           background-color="#24252A"
           text-color="#fff"
           active-text-color="#23961A"
  >
    <el-menu-item style="color: #23961A;font-size: 34px"><img src="../assets/logo.png" alt="" width="77" height="64" > 精维科技</el-menu-item>
    <el-menu-item style="font-size:16px ;margin-left: 70px" index="/" >首页</el-menu-item>
    <el-menu-item style="font-size:16px " index="/solution">解决方案</el-menu-item>
    <el-menu-item style="font-size:16px " index="/product">产品中心</el-menu-item>
    <el-menu-item style="font-size:16px " index="/projectCase">项目案例</el-menu-item>
    <el-menu-item style="font-size:16px " index="/news">新闻动态</el-menu-item>
    <el-menu-item style="font-size:16px " index="/JoinInvestment">招商加盟</el-menu-item>
    <el-menu-item style="font-size:16px " index="/aboutUs">关于我们</el-menu-item>
    <el-menu-item style="font-size:16px " index="/contactUs">联系我们</el-menu-item>
  </el-menu>
</div>
  </div>
</template>

<script>
export default {
  name: "headNavMenu",
  data() {
    return {
      activeIndex: '/',
      username: '',
      companyName: '公司',
      NavHead: '',
    };
  },
  watch: {
    "$route"(val, oldval) {
      setTimeout(() => {
        if (val.path !== oldval.path) {
          // this.NavHead = localStorage.getItem('changeNavHead')
        }

      }, 100)

    }
  },
  mounted: function () {
    if (localStorage.getItem('url_wuliawnag')) {
      this.activeIndex = localStorage.getItem('jingweikeji')
    }
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath,'22222222222222');
      this.$router.push(key)
      localStorage.setItem('jingweikeji', key)
    }
  }
}
</script>

<style>
@media (max-width: 1550px) {
  .line {
    /*margin-left: 20px;*/
    width: 1200px;
    margin: 0 auto;
    height: 103px;
  }
}

@media (min-width: 1550px) {
  .line {
    margin: 0 auto;
    background: #006837;
    width: 1200px;
    height: 103px;
  }
}

.head-ttile-name {
  font-size: 16px;
  color: #FFFFFF;
  text-decoration: none;
  line-height: 60px;
  margin-left: 20px;
}

.el-menu-item.is-disabled {
  opacity: 1 !important;
}

#navtit .el-menu--horizontal>.el-menu-item {
  float: left;
  height: 100px;
  line-height: 100px;
  margin: 0;
  border-bottom: 3px solid transparent;
  color: #909399;
}
.el-menu.el-menu--horizontal {
  border-bottom: solid 1px rgb(36, 37, 42) !important;
}
</style>
