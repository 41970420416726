<template>
<div style="background: #333333;color: #FFFFFF;font-size: 12px">
  <div class="officialButton">
<!--    <div class="flexBox topblogroll">-->
<!--     <div class="blogrollTitle">友情链接：</div>-->
<!--     <div class="blogroll"><a href="">中国农业信息网</a></div>-->
<!--     <div class="blogroll"><a href="">中国农业信息网</a></div>-->
<!--     <div class="blogroll"><a href="">中国农产品质量安全监管网</a></div>-->
<!--     <div class="blogroll"><a href="">山东省农业农村厅</a></div>-->
<!--     <div class="blogroll"><a href="">中华人民共和国农业农村部</a></div>-->
<!--    </div>-->
    <div class="flexBox topblogroll"  style="margin-top: 40px;padding-bottom: 30px">
      <div class="boxOne1">
          <div class="boxOneTitle">关于我们</div>
          <div class="boxOneCon"><a href="">公司简介</a></div>
          <div class="boxOneCon"><a href="">企业文化</a></div>
          <div class="boxOneCon"><a href="">关于我们</a></div>
      </div>
      <div class="boxOne2">
        <div  class="boxOneTitle">解决方案</div>
        <div class="boxOneCon"><a href="">视频监控信息化</a></div>
        <div class="boxOneCon"><a href="">智慧农业物联网</a></div>
        <div class="boxOneCon"><a href="">食用农产品合格证</a></div>
      </div>
      <div class="boxOne3">
        <div class="boxOneTitle">产品中心</div>
        <div class="boxOneCon"><a href="">食合源小程序</a></div>
        <div class="boxOneCon"><a href="">农业物联网控制器</a></div>
        <div class="boxOneCon"><a href="">农业物联网采集器</a></div>
        <div class="boxOneCon"><a href="">智慧农业物联网管理系统</a></div>
        <div class="boxOneCon"><a href="">食用农产品合格证掌中宝</a></div>
        <div class="boxOneCon"><a href="">食用农产品合格证管理系统</a></div>

      </div>
      <div class="boxOne1">
        <div class="boxOneTitle">新闻动态</div>
        <div class="boxOneCon"><a href="">常见问题</a></div>
        <div class="boxOneCon"><a href="">行业新闻</a></div>
        <div class="boxOneCon"><a href="">公司新闻</a></div>
      </div>
      <div class="boxOne4">
        <div class="boxOneTitleTel">联系我们</div>
        <div class="boxOneCon"><a href="">联系电话：13953189336 </a></div>
        <div class="boxOneCon"><a href="">传真：0531-81217977</a></div>
        <div class="boxOneCon"><a href="">地址：山东省济南市高新区齐鲁软件园大厦A座</a></div>
      </div>
      <div>
        <div class="boxOne5">
          <div style="background: #FFFFFF;width:130px ;height: 130px;margin-left: 45px">
            <img src="../assets/code.png" alt="" width="130" >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
}
</script>

<style scoped>
.flexBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

}
@media (max-width: 1550px) {
  .officialButton {
    width: 1200px;
    margin: 0 auto;
  }
}

@media (min-width: 1550px) {
  .officialButton {
    margin: 0 auto;
    width: 1200px;
  }
}

.blogrollTitle{
  font-size: 16px;
}
.topblogroll{
  padding-top: 50px;
}
.blogroll{
  margin-left: 39px;
  margin-top: 3px;
}

.boxOneTitle{
  margin-bottom: 20px;
  font-size: 16px;
}

.boxOneTitleTel{
  margin-bottom: 10px;
  font-size: 16px;
}

a{
  text-decoration:none;
  color: #ffffff;
}

.boxOne1{
  width: 80px;
  text-align: center;
  margin-right: 80px;
}

.boxOne2{
  width: 120px;
  text-align: center;
  margin-right: 80px;
}

.boxOne3{
  width: 160px;
  text-align: center;
  margin-right: 80px;
}
.boxOne4{
  width: 260px;
}
.boxOne5{
  width: 180px;

}

.boxOneCon{
  margin-top: 10px;
}
.boxOneConTEl{
  font-size: 32px;
  font-family: Microsoft YaHei;
  font-weight: 400;

}
</style>
