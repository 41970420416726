import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);

import router from './router'

Vue.config.productionTip = false;

import api from './api/index.js'
import axios from 'axios'

import AmapVue  from 'vue-amap'

Vue.use(AmapVue);
// eslint-disable-next-line no-undef
AmapVue.initAMapApiLoader({
    key: 'c1491a41ed12637c4aaab4f283a09522',
    v:'1.4.4'
})
// 将API方法绑定到全局
Vue.prototype.$api = api
axios.defaults.baseURL = '/apis'
// 拦截器请求
axios.interceptors.request.use(
    config => {
        if (localStorage.token) {
            // 判断是否存在token，如果存在的话，每个http header都加上Authorization
            config.headers.Authorization = `${localStorage.token}`;
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    })

// 拦截器响应
axios.interceptors.response.use(function (response) { // ①10010 token过期（30天） ②10011 token无效
    if (response.data.code == 401) {
        // window.location.href = "/";
        ElementUI.Message({
            message: '异常错误',
            type: 'error'
        })
    }
    return response
}, function (error) {
    return Promise.reject(error)
})

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
