import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
      path: '/',
      name: 'index',
      component: ()=> import('../views/home/index')
    },    {
      path: '/solution',
      name: 'solution',
      component: ()=> import('../views/home/solution')
    },     {
      path: '/solutionDetail',
      name: 'solutionDetail',
      component: ()=> import('../views/home/solutionDetail')
    },   {
      path: '/product',
      name: 'product',
      component: ()=> import('../views/home/product')
    },   {
      path: '/productDetail',
      name: 'productDetail',
      component: ()=> import('../views/home/productDetail')
    },   {
      path: '/projectCase',
      name: 'projectCase',
      component: ()=> import('../views/home/projectCase')
    },  {
      path: '/projectCaseDetail',
      name: 'projectCaseDetail',
      component: ()=> import('../views/home/projectCaseDetail')
    },  {
      path: '/news',
      name: 'news',
      component: ()=> import('../views/home/news')
    }, {
      path: '/newsDetail',
      name: 'newsDetail',
      component: ()=> import('../views/home/newsDetail')
    }, {
      path: '/JoinInvestment',
      name: 'JoinInvestment',
      component: ()=> import('../views/home/JoinInvestment')
    }, {
      path: '/aboutUs',
      name: 'aboutUs',
      component: ()=> import('../views/home/aboutUs')
    },{
      path: '/contactUs',
      name: 'contactUs',
      component: ()=> import('../views/home/contactUs')
    },
  ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
