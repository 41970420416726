<template>
  <div id="app">
    <div class="body">
      <head-nav-menu></head-nav-menu>
      <router-view></router-view>
      <official-buttom></official-buttom>
    </div>
  </div>
</template>

<script>
import headNavMenu from "@/components/headNavMenu";
import officialButtom from "@/components/officialButtom";

export default {
  name: 'App',
  components: {
    headNavMenu,
    officialButtom
  },
}
</script>

<style>
.page {
  width: 1200px;
  margin: 0 auto;
}

/*.page2 {*/
/*  width: 100%;*/
/*  min-width: 1200px;*/
/*  background-size: cover;*/
/*}*/

.body {
  width: 100%;
  min-width: 1200px;
  min-height: 100vh;
  margin: 0 auto;
}

@media (max-width: 1550px) {
  .bodyContent {
    padding: 0 20px;
  }
}

@media (min-width: 1550px) {
  .bodyContent {

  }
}

</style>
